import React from 'react'
import { Router } from '@reach/router'
import AccountLoginPage from '~/components/Account/Login/Page'
import AccountPage from '~/components/Account/Page'
import AccountProfilePage from '~/components/Account/Profile/Page'
import AccountSubscriptionPage from '~/components/Account/Subscription/Page'
import AccountSubscriptionDetailPage from '~/components/Account/Subscription/Detail/Page'
import PrivateRoute from '~/components/Account/PrivateRoute'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import {
  useTranslate,
  useSubscriptions
} from '@chordcommerce/gatsby-theme-autonomy'
import { SubscriptionProvider } from '~/components/Account/Subscription/Context/SubscriptionContext'
import { useLocation } from '@reach/router';
import { toURL } from '~/utils/intl/localePrefix';

const Account = () => {
  const translate = useTranslate()
  const subscriptions = useSubscriptions()
  const location = useLocation();
  return (
    <Layout>
      <SubscriptionProvider subscriptions={subscriptions}>
        <Metadata title={translate('account.page_title')} />

        <Router basepath={toURL(location && location.pathname, '/account')}>
          <AccountLoginPage path="/login" />

          <PrivateRoute path="/profile" component={AccountProfilePage} />
          <PrivateRoute
            path="/subscriptions"
            component={AccountSubscriptionPage}
          />
          <PrivateRoute
            path="/subscriptions/:subscriptionId"
            component={AccountSubscriptionDetailPage}
          />
          <PrivateRoute path="/" component={AccountPage} />
        </Router>
      </SubscriptionProvider>
    </Layout>
  )
}

export default Account
