/** @jsx jsx */
import { Link } from 'gatsby'
import Star from '~/assets/images/icons/solidStar.svg'
import { Box, Flex, Heading, Button, Text, jsx, useColorMode } from 'theme-ui'

const StarButton = ({ slug, icon, background, title, forwardSx }) => {
  return (
    <Box as={Link} to={slug} sx={{ cursor: 'pointer', ...forwardSx }}>
      <div
        sx={{
          position: 'relative',
          width: ['130px', '197px'],
          // height: 'fit-content',
          cursor: 'pointer',
          img: {
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            left: '50%',
            top: '50%',
            zIndex: 1,
            height: ['70px', '90px'],
            width: 'auto'
          },
          '&:hover': {
            img: {
              transform: 'translate(-50%, -50%) scale(1.5)'
            },
            svg: {
              animation: 'rotate 10s linear infinite'
            }
          }
        }}
      >
        <img src={icon} loading="eager" alt="Goodles Logo" />
        <Star height="80%" fill={background} />
      </div>
      <Text
        variant="text.subscription"
        as="p"
        sx={{
          textAlign: 'center',
          py: '10px',
          textDecoration: 'underline',
          color: background
        }}
      >
        {title}
      </Text>
    </Box>
  )
}
export default StarButton
