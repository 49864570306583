/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from 'gatsby'
import { trackClickProduct } from '@chordcommerce/gatsby-theme-autonomy/src/redux/actions/analytics'
import { useDispatch } from 'react-redux'
import useProductOverHeadImage from '~/hooks/components/use-product-overhead-image'

import Img from 'gatsby-image'

const SummaryImage = ({ slug, sku, forwardSx }) => {
  const path = sku.includes('|') ? 'bundles' : 'products'
  const url = `/${path}/${slug}/`
  const dispatch = useDispatch()
  const { image } = useProductOverHeadImage({ sku })
  if (!image) {
    return null
  }

  return (
    <Link to={url} onClick={() => dispatch(trackClickProduct(slug))}>
      <Img
        style={{
          overflow: 'visible',
          position: 'absolute',
          left: 0,
          //transform: 'translate(-50%, -50%)',
          height: '120px',
          width: '120px',
          ...forwardSx,
        }}
        imgStyle={{
          objectFit: 'cover',
        }}
        fluid={image?.fluid}
        alt={image?.title}
      />
    </Link>
  )
}

export default SummaryImage
