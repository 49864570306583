/** @jsx jsx */
import { Container, Flex, Heading, jsx } from 'theme-ui'
import ReturnButton from '../Return'
import SubscriptionInfoNote from '../Subscription/SubscriptionInfo'

const ProfileLayout = ({ children, title, link, isSubscriptionPage }) => {
  return (
    <Container m="auto" sx={{ minHeight: '60.6vh' }}>
      <Flex
        sx={{
          flexDirection: 'column',
          marginBottom: ['1rem', '2rem'],
          marginTop: ['0', null, '42px'],
          textAlign: ['center', null, 'left'],
          padding: ['24px 0px 0px', '42px 36px 20px']
        }}
      >
        <Flex
          sx={{
            flexDirection: ['column-reverse', 'row'],
            lineHeight: '60px',
            gap: ['20px', 0],
            mb: ['20px', 0]
          }}
        >
          <ReturnButton to={link} />

          <Heading
            as="h1"
            variant="text.h4"
            sx={{
              position: ['relative', 'absolute'],
              left: '50%',
              transform: 'translateX(-50%)',
              display: 'inline',
              verticalAlign: 'middle',
              textAlign: 'center',

              color: 'secondary'
            }}
          >
            {title}
          </Heading>
        </Flex>
      </Flex>
      {children}
      {isSubscriptionPage && (
        <SubscriptionInfoNote
          forwardSx={{
            textAlign: 'center',
            color: 'secondary',
            border: 0,
            fontSize: '18px',
            a: {
              display: 'block',
              textAlign: 'center',
              family: 'sofia',
              fontWeight: 'bold'
            }
          }}
        />
      )}
    </Container>
  )
}

export default ProfileLayout
