/** @jsx jsx */
import { useEffect } from 'react'
import { jsx, useColorMode } from 'theme-ui'
import {
  useTranslate,
  useSubscriptions
} from '@chordcommerce/gatsby-theme-autonomy'
import SubscriptionList from '~/components/Account/Subscription/List'
import ProfileLayout from '../../Layout'

const AccountSubscriptionPage = ({ children }) => {
  const translate = useTranslate()
  const subscriptions = useSubscriptions()
  const [colorMode, setColorMode] = useColorMode('Cheddy-Mac')

  //run on mount
  useEffect(() => {
    setColorMode('Cheddy-Mac')
  }, [])

  return (
    <ProfileLayout
      title={translate('subscriptions.page_title')}
      link="/account"
      isSubscriptionPage={true}
    >
      <SubscriptionList subscriptions={subscriptions} />
    </ProfileLayout>
  )
}

export default AccountSubscriptionPage
