/** @jsx jsx */
import { useEffect } from 'react'
import { jsx, useColorMode } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import AccountProfileForm from './../Form'

import ProfileLayout from '../../Layout'

const AccountProfilePage = () => {
  const translate = useTranslate()
  const [colorMode, setColorMode] = useColorMode('Cheddy-Mac')

  //run on mount
  useEffect(() => {
    setColorMode('Cheddy-Mac')
  }, [])

  return (
    <ProfileLayout title="Profile" link="/account/">
      <AccountProfileForm />
    </ProfileLayout>
  )
}

export default AccountProfilePage
