/** @jsx jsx */
import { useEffect } from 'react'
import { Box, Heading, jsx, useColorMode } from 'theme-ui'
import { navigate } from 'gatsby'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import AuthLoginForm from '~/components/Auth/LoginForm'
import { useLocation } from '@reach/router';
import { toURL } from '~/utils/intl/localePrefix';
const AccountLoginPage = () => {
  const translate = useTranslate()
  const [colorMode, setColorMode] = useColorMode('Twist-My-Parm')
  const location = useLocation()
  //run on mount
  useEffect(() => {
    setColorMode('Twist-My-Parm')
  }, [])

  const onLogin = () => {
    navigate(toURL(location && location.pathname, '/account'))
  }

  return (
    <Box
      sx={{
        margin: ['0 auto 1.5rem auto', null, '42px auto 4.5rem auto'],
        maxWidth: '600px'
      }}
    >
      <Heading
        as="h1"
        variant="text.h3"
        sx={{
          textAlign: 'center',
          color: 'tertiary',
          fontSize: ['28px', '40px'],
          marginTop: ['16px', null, '36px'],
          marginBottom: ['60px', '40px']
        }}
      >
        {translate('login.page_title')}
      </Heading>
      <Box
        sx={{
          margin: ['0 auto 1.5rem auto', null, '42px auto 4.5rem auto'],
          maxWidth: '600px'
        }}
      >
        <AuthLoginForm onSuccess={onLogin} />
      </Box>
    </Box>
  )
}

export default AccountLoginPage
