/** @jsx jsx */
import { Text, jsx } from 'theme-ui'
import { Fragment } from 'react'

const PriceStrikeOut = ({ total, ...props }) => {
  return (
    <Fragment>
      <Text variant="subscription" {...props}>
        <strike>${total.priceBeforeDiscount.toFixed(2)}</strike>{' '}
        <Text color="#03A009">${total.priceAfterDiscount.toFixed(2)}</Text>
      </Text>
    </Fragment>
  )
}

export default PriceStrikeOut
