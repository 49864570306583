/** @jsx jsx */
import { Box, Button, Text, jsx, useColorMode, Flex } from 'theme-ui'
import {
  useTranslate,
  useSubscription
} from '@chordcommerce/gatsby-theme-autonomy'

import SubscriptionDetailsAddressForm from '~/components/Account/Subscription/Detail/AddressForm'

import { Fragment, useEffect, useContext, useState } from 'react'

import { Link } from 'gatsby'

import { getStripe } from '~/utils/stripe/getStripe'
import { Elements } from '@stripe/react-stripe-js'

import { ChordSubscriptionContext } from '../../Context/SubscriptionContext'
import PriceStrikeOut from '../../SubscriptionCard/PriceStrikeOut'
import State from '../../SubscriptionCard/State'
import SubscriptionCard from '../../SubscriptionCard'
import StripeCard from '../AddressForm/StripeCard'
import CancelPopUp from './CancelPopUp'
import ProfileLayout from '~/components/Account/Layout'
import RowWrapper from './RowWrapper'
import Spinner from '~/components/Generic/Spinner'

// Call out of component to avoid rerenders
const stripe = getStripe()

const AccountSubscriptionDetailPage = ({ subscriptionId }) => {
  const [currentSubscriptionContext, setCurrentSubscriptionContext] =
    useState(null)

  const [message, setMessage] = useState(null)
  const [apiError, setApiError] = useState(null)

  const context = useContext(ChordSubscriptionContext)
  useEffect(() => {
    // grab the current subscription from the list of subscriptions by its id
    const res = context.find(c => c.id == subscriptionId)
    setCurrentSubscriptionContext(res)
  }, [context])
  const translate = useTranslate()

  if (!currentSubscriptionContext)
    return (
      <Flex sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Spinner />
      </Flex>
    )
  return (
    <Elements stripe={stripe}>
      {/* TODO Make a message context that wraps around the entire site. */}
      {message && (
        <Box
          sx={{
            '@keyframes fadeIn': {
              '0%': {
                opacity: 0,
                transform: 'translateX(-50%) translateY(10%) rotateX(10deg)'
              },
              '40%': {
                opacity: 1
              }
            },
            color: 'tertiary',
            backgroundColor: 'secondary',
            padding: '10px',
            px: '20px',
            minWidth: '100px',
            left: '50%',
            transform: 'translateX(-50%)',
            animation: 'fadeIn 2 alternate',
            animationDuration: '1s',
            opacity: message ? 1 : 0,
            textAlign: 'center',
            borderRadius: '8px',
            position: 'fixed',
            zIndex: 999,
            top: 20
          }}
          onAnimationEnd={() => setMessage(null)}
        >
          {message}
        </Box>
      )}

      {apiError && (
        <Box
          sx={{
            '@keyframes fadeIn': {
              '0%': {
                opacity: 0,
                transform: 'translateX(-50%) translateY(10%) rotateX(10deg)'
              },
              '40%': {
                opacity: 1
              }
            },
            color: 'tertiary',
            backgroundColor: '#FF5947',
            padding: '10px',
            px: '20px',
            minWidth: '100px',
            left: '50%',
            transform: 'translateX(-50%)',
            animation: 'fadeIn 2 alternate',
            animationDuration: '1s',
            opacity: message ? 1 : 0,
            textAlign: 'center',
            borderRadius: '8px',
            position: 'fixed',
            zIndex: 999,
            top: 20
          }}
          onAnimationEnd={() => setMessage(null)}
        >
          {apiError}
        </Box>
      )}

      <ProfileLayout
        title={translate('subscriptions.details')}
        link="/account/subscriptions"
        isSubscriptionPage={true}
      >
        {subscriptionId && (
          <Text
            sx={{
              textAlign: 'center',
              display: 'block',
              mb: '3rem',

              fontSize: '18px',
              color: 'secondary'
            }}
          >
            Subscription ID: <strong>{subscriptionId}</strong>
          </Text>
        )}
        <DetailFragment
          setApiError={setApiError}
          apiError={apiError}
          setMessage={setMessage}
          currentSubscriptionContext={currentSubscriptionContext}
          subscriptionId={subscriptionId}
          context={context}
        />
      </ProfileLayout>
    </Elements>
  )
}

const DetailFragment = ({
  currentSubscriptionContext,
  subscriptionId,
  setApiError,
  apiError,
  setMessage
}) => {
  const translate = useTranslate()

  const [cancelPop, setCancelPop] = useState(false)
  const [skipPop, setSkipPop] = useState(false)
  const [pausePop, setPausePop] = useState(false)
  const { filteredLineItems, total, findVariant, findProduct } =
    currentSubscriptionContext

  const [colorMode, setColorMode] = useColorMode('Cheddy-Mac')
  const {
    isLoaded,
    isFetching,
    subscription,
    skipSubscription,
    cancelSubscription,
    resumeSubscription,
    pauseSubscription
  } = useSubscription(subscriptionId)

  const date = new Date(subscription.actionableDate).toLocaleDateString(
    'en-US',
    {
      timeZone: 'UTC'
    }
  )

  const setNewDate = (unit, unitLength, dateString) => {
    const date = new Date(dateString)
    unit === 'day' && date.setDate(date.getDate() + unitLength)
    unit === 'month' && date.setMonth(date.getMonth() + unitLength)
    unit === 'year' && date.setFullYear(date.getFullYear() + unitLength)
    return date.toLocaleDateString('en-US', {
      timeZone: 'UTC'
    })
  }

  const skipSubscriptionDate = setNewDate(
    subscription.intervalUnits,
    subscription.intervalLength,
    subscription.actionableDate
  )

  const [billForm, setBillForm] = useState(false)
  const [shipForm, setShipForm] = useState(false)

  const setAllPop = x => {
    setCancelPop(x)
    setPausePop(x)
    setSkipPop(x)
  }

  const skipSub = async () => {
    try {
      setApiError(null)
      await skipSubscription()
      setMessage(`Subscription Skipped`)
    } catch (e) {
      console.error(e)
      setApiError(translate('error.api.default'))
    } finally {
      setAllPop(false)
    }
  }

  const cancelSub = async () => {
    try {
      setApiError(null)
      await cancelSubscription()
      setMessage('Cancelling subscription..')
      // set cancellation message based on state === "cancelled"
    } catch (e) {
      setApiError(translate('error.api.default'))
    } finally {
      setAllPop(false)
    }
  }

  const pauseSub = async () => {
    try {
      setApiError(null)
      await pauseSubscription()
      setMessage('Subscription Paused')
    } catch (e) {
      setApiError(translate('error.api.default'))
    } finally {
      setAllPop(false)
    }
  }

  const resumeSub = async () => {
    try {
      setApiError(null)
      await resumeSubscription()
      setMessage(`Subscription Resumed`)
    } catch (e) {
      setApiError(translate('error.api.default'))
    }
  }

  //run on mount
  useEffect(() => {
    setColorMode('Cheddy-Mac')
  }, [])

  const lastDigits = `${subscription?.payment?.source.ccType} *${subscription?.payment?.source?.lastDigits}`
  const subscriptionCancelState =
    subscription.state === 'canceled' ||
    subscription.state === 'pending_cancellation'

  if (!isLoaded) return null

  return (
    <Fragment>
      <CancelPopUp
        close={() => setCancelPop(false)}
        popUp={cancelPop}
        state={subscription.state}
        apiError={apiError}
        isFetching={isFetching}
        event={cancelSub}
        slug="subscription-cancel-box"
        pause={pauseSub}
        type="cancel"
      />
      <CancelPopUp
        close={() => setSkipPop(false)}
        popUp={skipPop}
        state={subscription.state}
        apiError={apiError}
        isFetching={isFetching}
        event={skipSub}
        pause={pauseSub}
        renewalDate={skipSubscriptionDate}
        slug="subscription-skip-box"
        type="skip"
      />
      <CancelPopUp
        close={() => setPausePop(false)}
        popUp={pausePop}
        state={subscription.state}
        apiError={apiError}
        isFetching={isFetching}
        event={pauseSub}
        pause={pauseSub}
        slug="subscription-pause-box"
        type="pause"
      />

      <Box
        sx={{
          width: ['100%', '80%'],
          textAlign: 'left',
          margin: 'auto',
          color: 'secondary',
          fontSize: '20px'
        }}
      >
        <RowWrapper>
          <Text>Items:</Text>
          <Box>
            {filteredLineItems.map((item, idx) => {
              const variant = findVariant(item.sku)
              const product = findProduct(item.sku)

              return (
                <Fragment key={`${item.sku}__${idx}`}>
                  <Text variant="subscription">{product.name}</Text>

                  <Text
                    variant="subscription"
                    sx={{
                      ml: '10px',

                      alignSelf: 'auto'
                    }}
                  >
                    x{item.totalQuantity}
                  </Text>

                  <Text
                    as="p"
                    variant="subscription"
                    sx={{ fontSize: '15px', color: '#03A009' }}
                  >
                    {variant.optionValues[0].presentation} Pack
                  </Text>
                </Fragment>
              )
            })}
          </Box>
          <SubscriptionCard subscription={currentSubscriptionContext} hero />
        </RowWrapper>
        {subscription.state === 'active' && (
          <RowWrapper>
            <Text>Next order:</Text>
            <Text
              sx={{
                fontSize: 3,
                fontFamily: 'heading'
              }}
            >
              {date}
            </Text>

            <Button
              variant="buttons.subscription"
              onClick={() => setSkipPop(true)}
            >
              {translate('subscriptions.skip')}
            </Button>
          </RowWrapper>
        )}
        <RowWrapper>
          <Text sx={{ textAlign: 'left' }}>Renews every:</Text>
          <Text
            sx={{
              fontSize: 3,
              fontFamily: 'heading',
              textTransform: 'capitalize'
            }}
          >
            {subscription.intervalUnits}
          </Text>
          {!subscriptionCancelState ? (
            <Button
              variant="buttons.subscription"
              sx={{
                backgroundColor: '#45EEFF'
              }}
              onClick={
                subscription.state === 'paused'
                  ? resumeSub
                  : () => setPausePop(true)
              }
            >
              {subscription.state === 'paused' ? 'Resume' : 'Pause'}
            </Button>
          ) : (
            <Button
              as={Link}
              variant="buttons.subscription"
              to={'/shop'}
              onClick={pauseSubscription}
            >
              Shop Now
            </Button>
          )}
        </RowWrapper>
        <RowWrapper>
          <Text>Status:</Text>
          <State state={subscription.state} />
          {!subscriptionCancelState && (
            <Button variant="buttons.link" onClick={() => setCancelPop(true)}>
              {translate('subscriptions.cancel')}
            </Button>
          )}
        </RowWrapper>

        <RowWrapper>
          <Text>Price:</Text>
          <PriceStrikeOut total={total} />
        </RowWrapper>
        <RowWrapper>
          <Text>Shipping:</Text>
          <Text sx={{ fontSize: 3, fontFamily: 'heading' }}>
            {shipForm ? 'Editing...' : subscription?.shipAddress?.address1}
          </Text>
          {!shipForm && (
            <Button onClick={() => setShipForm(true)} variant="buttons.link">
              Edit
            </Button>
          )}
        </RowWrapper>
        {shipForm && (
          <SubscriptionDetailsAddressForm
            subscription={subscription}
            close={() => setShipForm(false)}
            addressType={'shipAddress'}
          />
        )}
        <RowWrapper>
          <Text>Billing:</Text>
          <Text
            sx={{
              fontSize: 3,
              fontFamily: 'heading',
              textTransform: 'capitalize'
            }}
          >
            {billForm ? 'Editing...' : lastDigits}
          </Text>

          {!billForm && (
            <Button onClick={() => setBillForm(true)} variant="buttons.link">
              Edit
            </Button>
          )}
        </RowWrapper>
        {billForm && (
          <StripeCard
            cardInfo={lastDigits}
            subscription={subscription}
            close={() => setBillForm(false)}
          />
        )}
      </Box>
    </Fragment>
  )
}

export default AccountSubscriptionDetailPage
