/** @jsx jsx */
import { navigate } from 'gatsby'
import { useEffect, useState } from 'react'
import { Label, Flex, Button, Box, Input, Text, jsx } from 'theme-ui'

import { useForm } from 'react-hook-form'
import { useTranslate, useUser } from '@chordcommerce/gatsby-theme-autonomy'
import { identifyUser } from '@chordcommerce/gatsby-theme-autonomy/src/services/analytics'
import RowWrapper from '~/components/Account/Subscription/Detail/Page/RowWrapper'
import Spinner from '~/components/Generic/Spinner'

const thatWindow = typeof window !== 'undefined' ? window : null

const buttonStyle = {
  color: 'secondary',
  maxWidth: '138px',
  height: '44px',
  fontSize: '20px',
  fontFamily: 'heading',
  textTransform: 'capitalize'
}

const inputStyle = {
  gridColumn: '2 / -1',
  border: '1px solid',
  borderColor: 'secondary',
  color: 'secondary',
  borderRadius: '8px',
  background: 'white',
  '&:focus': { color: 'secondary', borderColor: 'secondary' }
}

const flexStyle = {
  display: ['flex', 'grid'],
  gridTemplateColumns: '1fr 1fr 138px',
  flexDirection: ['column', 'row'],
  justifyContent: 'space-between',
  alignItems: 'center',
  mb: '40px',
  alignItems: ['left', 'center']
}

const openChat = () => {
  thatWindow && thatWindow.GorgiasChat && thatWindow.GorgiasChat.open()
}

const gorgiasIsLive =
  thatWindow &&
  thatWindow.GorgiasChat &&
  thatWindow.GorgiasChat.isBusinessHours()
const AccountProfileForm = () => {
  const translate = useTranslate()
  const { user, modifyUser, loadUser } = useUser()
  useEffect(() => {
    loadUser()
  }, [loadUser])

  const { register, handleSubmit } = useForm()

  const [editName, setEditName] = useState(false)
  const [editEmail, setEditEmail] = useState(false)
  const [editPhone, setEditPhone] = useState(false)

  const handleShowEdit = (e, type) => {
    e.preventDefault()
    type === 'name' && setEditName(!editName)
    type === 'email' && setEditEmail(!editEmail)
    type === 'phone' && setEditPhone(!editPhone)
  }

  const closeAll = e => {
    e.preventDefault()
    setEditName(false)
    setEditEmail(false)
    setEditPhone(false)
  }

  // TODO: check valid email and valid phone number
  // - add error message if API error to contact support.

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const onSubmit = async data => {
    setLoading(true)
    setError(null)
    const { email, name, phone } = data
    // try {
    //   checkValidEmail(email)
    // } catch (e) {
    //   setError(e)
    //   setLoading(false)
    //   return
    // }
    if (phone) {
      try {
        checkValidPhone(phone)
      } catch (e) {
        setError('Phone is not valid!')
        setLoading(false)
        return
      }
    }

    // send updated user info to Segment to parse and update in Klaviyo
    identifyUser(user.data.id, {
      phone: phone,
      oldPhone: user.data.phone,
      name: name,
      oldName: user.data.name,
      email: email,
      oldEmail: user.data.email
    })
    try {
      await modifyUser({ ...user, name, phone })
    } catch (e) {
      setError(
        "either the email you have entered is already being used, or there's an issue changing your info"
      )
    } finally {
      setLoading(false)
      setEditName(false)
      setEditEmail(false)
      setEditPhone(false)
    }
  }

  const checkValidEmail = email => {
    if (!email) {
      throw 'Email cannot be empty'
    }
    if (
      email &&
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    )
      throw 'Email is invalid'
    return
  }

  const checkValidPhone = phone => {
    if (phone && !/^\d+$/.test(phone)) throw 'invalid phone number'
    return
  }

  return (
    <Box
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        color: 'secondary',
        width: ['100%', '80%'],
        textAlign: 'left',
        margin: 'auto',
        pb: '1rem',
        color: 'secondary',
        fontSize: '20px',
        input: {
          fontFamily: 'body'
        }
      }}
    >
      {!editName ? (
        <RowWrapper>
          <Text>{translate('address.name')}</Text>

          <Text variant="subscription">{user.data.name}</Text>

          <Button
            variant="buttons.link"
            onClick={(e, type = 'name') => handleShowEdit(e, type)}
          >
            Edit
          </Button>
        </RowWrapper>
      ) : (
        <Flex sx={flexStyle}>
          <Text>{translate('address.name')}</Text>
          <Input
            sx={inputStyle}
            type="text"
            ref={register({ maxLength: 80 })}
            name="name"
            defaultValue={user.data.name}
          />
        </Flex>
      )}

      <RowWrapper>
        <Text>{translate('profile.email_input_label')}</Text>
        {editEmail ? (
          <Input
            variant="forms.input"
            ref={register()}
            name="email"
            type="text"
            mb="2"
            defaultValue={user.data.email}
          />
        ) : (
          <Text variant="subscription">{user.data.email}</Text>
        )}
        {/* {!editEmail && (
          <Button
            variant="buttons.link"
            onClick={(e, type = 'name') => handleShowEdit(e, type)}
          >
            Edit
          </Button>
        )} */}
      </RowWrapper>
      <RowWrapper>
        <Text mb="1" htmlFor="name">
          Phone
        </Text>
        {editPhone ? (
          <Box>
            <Input
              variant="forms.input"
              ref={register()}
              name="phone"
              type="tel"
              mb="2"
              defaultValue={user.data.phone}
            />
            {error && (
              <Box sx={{ textAlign: 'left' }}>
                <Text variant="formError">{error}</Text>
                <Text as="p">
                  For further assistance please reach out to us at{' '}
                  <a
                    sx={{
                      fontWeight: 'bold',
                      textDecoration: 'underline',
                      cursor: 'pointer'
                    }}
                    href="mailto:help@goodles.com"
                  >
                    help@goodles.com
                  </a>
                  {gorgiasIsLive && (
                    <Text>
                      ,<br></br>
                      or click{' '}
                      <Text
                        sx={{
                          fontWeight: 'bold',
                          textDecoration: 'underline',
                          cursor: 'pointer'
                        }}
                        onClick={() => openChat()}
                      >
                        here
                      </Text>{' '}
                      to talk to us live
                    </Text>
                  )}
                  .
                </Text>
              </Box>
            )}
          </Box>
        ) : (
          <Text variant="subscription">{user.data.phone}</Text>
        )}
        {/* {!editPhone && (
          <Button
            variant="buttons.link"
            onClick={(e, type = 'phone') => handleShowEdit(e, type)}
          >
            Edit
          </Button>
        )} */}
      </RowWrapper>

      {(editEmail || editName || editPhone) && (
        <Flex sx={{ justifyContent: 'center', gap: '20px', mb: '40px' }}>
          <Button
            sx={
              (buttonStyle,
              {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              })
            }
          >
            {loading && (
              <Spinner data-testid="spinner" size={15} color="inherit" />
            )}
            {!loading && <Text>Update</Text>}
          </Button>
          <Button variant="buttons.link" onClick={e => closeAll(e)}>
            Cancel
          </Button>
        </Flex>
      )}
    </Box>
  )
}

export default AccountProfileForm
