/** @jsx jsx */
import { useEffect } from 'react'
import { navigate } from 'gatsby'
import { useAuth, useUser } from '@chordcommerce/gatsby-theme-autonomy'

import { Container, Flex, jsx } from 'theme-ui'

import Spinner from '~/components/Generic/Spinner'
import { useLocation } from '@reach/router';
import { toURL } from '~/utils/intl/localePrefix';

const PrivateRoute = ({ component: Component, ...props }) => {
  const { getToken, isLoggedIn, isFetching } = useAuth()
  const { user, loadUser } = useUser()
  const location = useLocation()
  useEffect(() => {
    const checkStatus = async () => {
      try {
        await getToken()

        if (!user.id) {
          await loadUser()
        }
      } catch (error) {
        navigate(toURL(location && location.pathname, '/account/login'))
      }
    }

    if (!isLoggedIn) checkStatus()
  }, [isLoggedIn, getToken, loadUser, user])

  if (!isLoggedIn && isFetching) {
    return (
      <Container>
        <Flex
          sx={{
            flexDirection: 'column',
            marginBottom: ['1.5rem', null, '4.5rem'],
            marginTop: ['0', null, '42px']
          }}
        >
          <Flex
            sx={{
              justifyContent: 'center',
              width: '100%',
              backgroundColor: 'transparent',
              padding: ['10rem 1.25rem', '12rem 1.25rem'],
              marginRight: [null, '1rem'],
              marginBottom: ['1rem', null]
            }}
          >
            <Spinner size={150} />
          </Flex>
        </Flex>
      </Container>
    )
  }

  if (!isLoggedIn && !isFetching) {
    return null
  }

  return <Component {...props} />
}

export default PrivateRoute
