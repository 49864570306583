import React, { createContext } from 'react'
import {
  getProductBySku,
  getPriceBySku,
  getVariantBySku
} from '~/utils/get-price-sku'
import useProducts from '~/hooks/graphql/queries/use-products'

export const ChordSubscriptionContext = createContext()

export const SubscriptionProvider = ({ subscriptions, children }) => {
  const additionalProperties = []

  // get all products 1x to avoid using hooks.
  const products = useProducts()

  const findVariant = sku => {
    return getVariantBySku(sku, products)
  }

  const findProduct = sku => {
    return getProductBySku(sku, products)
  }

  // map through all existing subscriptions and add additional data to subscription object
  subscriptions &&
    subscriptions.length &&
    subscriptions.map(subscription => {
      const product = getProductBySku(subscription.lineItems[0].sku, products)
      const { discountPercentage } = product?.subscription || { discountPercentage: 15 };
      // consolidate all duplicate line items
      const filteredArray = subscription.lineItems.filter(
        (value, index, self) =>
          index === self.findIndex(item => item.sku === value.sku)
      )

      // add up all duplicates' quantity
      filteredArray.map(item => {
        const list = subscription.lineItems.filter(
          lineItem => lineItem.sku === item.sku
        )
        const res = list.reduce((sum, i) => {
          return sum + i.quantity
        }, 0)
        item.totalQuantity = res

        item.priceBeforeDiscount =
          item.totalQuantity * getPriceBySku(item.sku, products)
        item.priceAfterDiscount =
          item.priceBeforeDiscount * (100 - discountPercentage) / 100;
      })

      // add up everything in the subscription object and apply discount
      const total = {
        priceBeforeDiscount: filteredArray.reduce(
          (sum, b) => sum + b.priceBeforeDiscount,
          0
        ),
        priceAfterDiscount: filteredArray.reduce(
          (sum, b) => sum + b.priceAfterDiscount,
          0
        )
      }

      const variant =
        filteredArray.length > 1
          ? null
          : findVariant(subscription.lineItems[0].sku)

      const customProperties = {
        ...subscription,
        id: subscription.id,
        variant: variant,
        product: product,
        filteredLineItems: filteredArray,
        total: total,
        findVariant: findVariant,
        findProduct: findProduct
      }
      additionalProperties.push(customProperties)
    })

  return (
    <ChordSubscriptionContext.Provider value={additionalProperties}>
      {children}
    </ChordSubscriptionContext.Provider>
  )
}
