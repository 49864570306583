export const getPriceBySku = (sku, products) => {
  let match = null
  products.find(
    product => (match = product.variants.find(variant => variant.sku === sku))
  )

  return match ? match.price : null
}

export const getProductBySku = (sku, products) => {
  const product = products.find(product =>
    product.variants.find(variant => variant.sku === sku)
  )

  return product
}

export const getVariantBySku = (sku, products) => {
  let match = null
  products.find(
    product => (match = product.variants.find(variant => variant.sku === sku))
  )

  return match
}
