/** @jsx jsx */
import { navigate } from 'gatsby-link'
import { Card, Box, Button, Text, jsx } from 'theme-ui'
import SummaryImage from '~/components/Account/Subscription/SummaryImage'
import { Fragment } from 'react'

import State from './State'
import PriceStrikeOut from './PriceStrikeOut'
// import ChordSubscriptionContext from '~/src/components/Account/Context/SubscriptionContext'

const SubscriptionCard = ({ hero, subscription }) => {
  const { filteredLineItems, total, product, variant } = subscription

  const renewalDate =
    subscription.state === 'active' &&
    //bug
    new Date(subscription.actionableDate).toLocaleDateString('en-US', {
      timeZone: 'UTC',
    })
  const animChildren = {}
  filteredLineItems.forEach(
    (item, i) =>
      (animChildren[`div:nth-child(${i + 1})`] = {
        transform: `rotate(${(i + 1) * 2}deg) translateX(${10 * -i}px)`,
      }),
  )

  if (hero)
    return (
      <Card
        sx={{
          borderRadius: 0,
          pb: [null, '40px'],
          height: ['70px'],
          transform: 'translateY(-50%)',
          transition: '1s',
          ':hover': {
            ...animChildren,
            '&:last-of-type': {
              border: 0,
            },
          },
        }}
      >
        {subscription &&
          filteredLineItems.map((lineItem, index) => {
            return (
              <Box
                key={`${lineItem.sku}_${index}`}
                sx={{
                  transformOrigin: 'center',
                  position: 'relative',
                  transition: '1s ease',
                  left: 0,
                  transform: `translateX(${index * 10}px) translateY(${
                    index * -4
                  }px)`,
                  opacity: (index + 1 - 0) / (filteredLineItems.length - 0),
                }}
              >
                <SummaryImage
                  forwardSx={{
                    width: `${120 - (filteredLineItems.length - 1) * 10}px`,
                  }}
                  slug={lineItem.productSlug}
                  sku={lineItem.sku}
                  key={`${lineItem.sku} - ${index}`}
                />
              </Box>
            )
          })}
      </Card>
    )

  return (
    <Card
      sx={{
        display: 'grid',
        justifyContent: ['center'],
        gridTemplateColumns: [
          'fit-content',
          'repeat(6, 1fr)',
          'repeat(7, 1fr)',
        ],

        gridTemplateAreas: [
          "'image name name' 'image state date' 'manage manage manage'",
          'none',
        ],
        gap: '10px',
        margin: 'auto',
        padding: 0,
        py: ['40px'],
        alignItems: 'center',
        borderRadius: 0,
        borderBottom: '1px solid',
        borderColor: 'tertiary',
        '&:last-of-type': {
          border: 0,
        },
      }}
    >
      <Box
        sx={{
          gridArea: ['image', 'auto'],
          width: `100px`,
          height: '120px',
          ':hover': {
            ...animChildren,
          },
          pr: ['40px'],
          position: 'relative',
        }}
      >
        {subscription &&
          filteredLineItems.map((lineItem, index) => {
            return (
              <Box
                sx={{
                  transformOrigin: 'center',
                  position: 'relative',
                  transition: '1s ease',
                  left: 0,
                  transform: `translateX(${index * 10}px) translateY(${
                    index * -4
                  }px)`,
                  opacity: (index + 1 - 0) / (filteredLineItems.length - 0),
                }}
              >
                <SummaryImage
                  forwardSx={{
                    width: `${120 - (filteredLineItems.length - 1) * 10}px`,
                  }}
                  slug={lineItem.productSlug}
                  sku={lineItem.sku}
                  key={`${lineItem.sku} - ${index}`}
                />
              </Box>
            )
          })}
      </Box>

      {filteredLineItems && product && (
        <Box sx={{ gridArea: ['name', 'auto'], textAlign: 'left' }}>
          {filteredLineItems.length === 1 ? (
            <Fragment>
              <Text variant="subscription">{product.name}</Text>
              <Text
                variant="subscription"
                sx={{
                  ml: '10px',
                  display: ['unset', 'none'],
                  alignSelf: 'auto',
                }}
              >
                x{filteredLineItems[0].totalQuantity}
              </Text>
              <Text
                as="p"
                variant="subscription"
                sx={{ fontSize: '15px', color: '#03A009' }}
              >
                {variant.optionValues[0].presentation} Pack
              </Text>
            </Fragment>
          ) : (
            <Fragment>
              <Text variant="subscription">Multiple Packs</Text>
              <Text
                as="p"
                variant="subscription"
                sx={{ fontSize: '15px', color: '#03A009' }}
              >
                Yum!
              </Text>
            </Fragment>
          )}
        </Box>
      )}

      <Text
        variant="subscription"
        sx={{ display: ['none', 'unset'], alignSelf: 'auto' }}
      >
        {filteredLineItems.length === 1 &&
          `x${filteredLineItems[0].totalQuantity}`}
      </Text>
      <PriceStrikeOut total={total} sx={{ display: ['none', null, 'unset'] }} />
      <State
        state={subscription.state}
        forwardSx={{ gridArea: ['state', 'auto'] }}
      />

      <Box sx={{ gridArea: ['date', 'auto'], justifySelf: 'left' }}>
        {renewalDate && (
          <Fragment>
            <Text variant="subscription" as="p">
              {renewalDate}
            </Text>
            <Text
              variant="subscription"
              as="p"
              sx={{
                display: ['none', 'block'],
                fontSize: '15px',
                color: '#03A009',
              }}
            >
              Next Order
            </Text>
          </Fragment>
        )}
      </Box>

      <Button
        onClick={() => navigate(`/account/subscriptions/${subscription.id}`)}
        type="submit"
        sx={{
          gridArea: ['manage', 'auto'],
          justifySelf: 'center',
          maxWidth: 'fit-content',
          px: '36px',
          m: 0,
          mt: ['20px', 0],
          display: 'block',
          fontSize: ['20px'],
        }}
      >
        Manage
      </Button>
    </Card>
  )
}

export default SubscriptionCard
