/** @jsx jsx */
import { Link } from 'gatsby'
import { useEffect, useState } from 'react'
import { Box, Flex, Heading, Button, Text, jsx, useColorMode } from 'theme-ui'
import copy from 'copy-to-clipboard'

import {
  useAuth,
  useTranslate,
  useUser,
  useAnalytics
} from '@chordcommerce/gatsby-theme-autonomy'
import Star from '~/assets/images/icons/solidStar.svg'

import StarButton from './StarButton'

const AccountPage = () => {
  const translate = useTranslate()
  const { user, loadUser, loadUserReferralIdentifier } = useUser()
  const { logout } = useAuth()
  const [colorMode, setColorMode] = useColorMode('Cheddy-Mac')
  const [copied, setCopied] = useState(false)

  //run on mount
  useEffect(() => {
    loadUser()
    if (user.data.email) {
      loadUserReferralIdentifier(user.data.email)
    }
    setColorMode('Cheddy-Mac')
  }, [user?.data?.storeCredit])

  const userStoreCredits =
    user.data.storeCredit && user.data.storeCredit !== '0.0'
      ? parseInt(user.data.storeCredit).toFixed(2)
      : null

  const referralPurl = user.referralIdentifier && user.referralIdentifier.purl

  return (
    <Box
      sx={{
        margin: ['0 auto 1.5rem auto', null, '42px auto 4.5rem auto'],
        maxWidth: '800px'
      }}
    >
      <Flex
        sx={{
          display: 'grid',
          gridGap: 4,
          marginTop: ['0', null, '42px'],
          textAlign: ['center', null, 'left'],
          justifyItems: 'center',
          padding: ['24px 16px', null, '42px 36px']
        }}
      >
        <Heading as="h1" variant="text.h3" color="secondary">
          {user.data.name ? user.data.name + '’s' : ''}{' '}
          {translate('account.page_title')}
        </Heading>
        <Flex
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
            mb: '20px'
          }}
        >
          {userStoreCredits && (
            <Text
              color="secondary"
              sx={{ fontFamily: 'body', fontWeight: 400 }}
            >
              Available in-store credits:{' '}
              <span sx={{ fontFamily: 'heading' }}>${userStoreCredits}</span>
            </Text>
          )}
          {referralPurl && (
            <Button
              onClick={() => {
                copy(referralPurl)
                setCopied(true)
                setTimeout(() => {
                  setCopied(false)
                }, 2000)
              }}
              disabled={copied}
              sx={{
                width: '100%',
                minWidth: '180px',
                maxWidth: '237px',
                margin: '0 auto'
              }}
            >
              {copied
                ? translate('referral.copy_link_button_clicked')
                : 'copy referral link'}
            </Button>
          )}
        </Flex>
        <Flex
          sx={{
            gap: ['20px', '54px'],
            mb: '40px',
            flexDirection: ['column', 'row']
          }}
        >
          <StarButton
            forwardSx={{ img: { height: ['50%', '70px'] } }}
            slug="/account/profile"
            icon="/images/logo.png"
            background="#FF5A47"
            title="Profile"
          ></StarButton>
          <StarButton
            forwardSx={{ img: { height: ['75%', '130px'] } }}
            slug="/account/subscriptions"
            icon="/images/variety.png"
            background="#007300"
            title="Subscriptions"
          ></StarButton>
        </Flex>
        <Button
          onClick={logout}
          variant="buttons.primary"
          sx={{ maxWidth: '200px', width: '100%' }}
        >
          {translate('account.logout')}
        </Button>
      </Flex>
    </Box>
  )
}

export default AccountPage
