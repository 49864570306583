import { graphql, useStaticQuery } from 'gatsby'

const useSubscriptionInfo = () => {
  const data = useStaticQuery(graphql`
    {
      notificationBar: contentfulNotificationBar(
        slug: { eq: "subscription-help-info" }
      ) {
        notification {
          slug
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `)
  return data
}
export default useSubscriptionInfo
