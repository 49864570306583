/** @jsx jsx */
import { Flex, jsx, Button, Input, Label, Text } from 'theme-ui'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { navigate } from 'gatsby'
import { useAuth, useTranslate } from '@chordcommerce/gatsby-theme-autonomy'

const AuthLoginForm = () => {
  const translate = useTranslate()
  const { register, handleSubmit } = useForm()
  const { login } = useAuth()
  const [error, setError] = useState(null)

  const onLogin = async ({ email }) => {
    setError(null)
    try {
      await login({ email, showUI: true, redirectURI: window.location.origin })
      navigate('/account')
    } catch (error) {
      setError(error)
    }
  }
  return (
    <Flex
      as="form"
      onSubmit={handleSubmit(onLogin)}
      sx={{
        flexDirection: 'column',
        mx: '2rem',
        color: 'tertiary',
      }}
    >
      <Flex mb="2" sx={{ flexDirection: 'column' }}>
        <Label variant="text.formLabel" mb="1" htmlFor="email">
          {translate('profile.email_input_label')}
        </Label>
        <Input
          variant="forms.input"
          ref={register()}
          name="email"
          type="text"
          sx={{ mb: '30px', borderWidth: '2px', background: 'white' }}
        />

        <Button
          type="submit"
          sx={{
            color: 'primary',
            ':hover': { color: '#FFDD00' },
            width: '100%',
          }}
        >
          <Text variant="link">{translate('login.sign_in')}</Text>
        </Button>
      </Flex>
      {error && (
        <p variant="text.formError" className="error">
          {error.message}
        </p>
      )}
    </Flex>
  )
}
export default AuthLoginForm
